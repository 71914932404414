import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { ProjectContext } from '@core/context';

import PaginationControls from '@ui/PaginationControls';

import ChangelogExcerpt from './Excerpt';
import ChangelogPost from './Post';
import classes from './style.module.scss';

const ChangelogList = ({ changelogs = [], page, pages, typeIconClasses, loading }) => {
  const { project } = useContext(ProjectContext);
  const {
    appearance: { changelog },
  } = project;
  const { showAuthor, showExactDate } = changelog;
  const prevPage = page > 1 && { queryParam: `page=${page - 1}` };
  const nextPage = page < pages && { queryParam: `page=${page + 1}` };
  return (
    <>
      {changelogs?.length === 0 && (
        <div className={classes.ChangelogList_empty}>{!loading && 'Looks like there are no posts!'}</div>
      )}
      {changelog.layoutExpanded ? (
        <>
          {changelogs?.map((log, i) => (
            <ChangelogPost
              key={`changelog-item-${i}-${log._id}`}
              changelog={log}
              className={classes['ChangelogPage_list-post']}
              exactDate={showExactDate}
              isList
              showAuthor={showAuthor}
              typeIconClasses={typeIconClasses}
            />
          ))}
        </>
      ) : (
        <>
          {changelogs?.map((log, i) => (
            <ChangelogExcerpt
              key={`changelog-item-${i}-${log._id}`}
              exactDate={showExactDate}
              log={log}
              showAuthor={showAuthor}
              typeIconClasses={typeIconClasses}
            />
          ))}
        </>
      )}
      {pages > 1 && (
        <div className={classes.ChangelogList_pagination}>
          <PaginationControls meta={`${page} of ${pages}`} next={nextPage} pageType="changelog" prev={prevPage} />
        </div>
      )}
    </>
  );
};

ChangelogList.propTypes = {
  changelogs: PropTypes.arrayOf(ChangelogExcerpt.propTypes.log),
  loading: PropTypes.bool,
  page: PropTypes.number,
  pages: PropTypes.number,
  typeIconClasses: PropTypes.object,
};

export default ChangelogList;
