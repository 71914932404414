import React from 'react';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';

import useClassy from '@core/hooks/useClassy';
import useEnvInfo from '@core/hooks/useEnvInfo';
import useRoute from '@core/hooks/useRoute';

import Button from '@ui/Button';
import ButtonGroup from '@ui/ButtonGroup';
import DateLine from '@ui/DateLine';
import Icon from '@ui/Icon';
import Spinner from '@ui/Spinner';

import SuggestionStatus from './components/Status';
import classes from './style.module.scss';

const SuggestionsListNullcase = ({ className, children }) => {
  const bem = useClassy(classes, 'SuggestionsList-nullcase');
  return <div className={bem('&', className)}>{children}</div>;
};

const SuggestionsList = props => {
  const bem = useClassy(classes, 'SuggestionsList');
  const query = new URLSearchParams(useLocation().search);
  const { url } = useRouteMatch();
  const { isServer } = useEnvInfo();
  const { state, loading, error } = useRoute(props);

  /**
   *  @note URLs with query params force-trigger an extra SWR loading state
   *  after the initial hydration. This causes an awakward flash of content
   *  on load which we can avoid by showing the loading state on the server-
   *  side render too if/when the requested URL has a `?status` query param.
   */ const SHOW_LOADING_STATE_ON_SSR = isServer && query.has('status');

  return (
    <div className={bem('&', 'rm-SuggestionsList')}>
      <header className={bem('-header')}>
        <h1 className={bem('-title')}>
          <Icon name="suggested-edits" />
          Your Suggestions
        </h1>
        <ButtonGroup selectedKey={query.get('status') || 'open'}>
          <Button key="open" outline size="sm" to="?">
            Open
          </Button>
          <Button key="closed" outline size="sm" to="?status=closed">
            Closed
          </Button>
        </ButtonGroup>
      </header>
      {error ? (
        <SuggestionsListNullcase className="_error">
          <Icon name="alert-octagon" />
          <br />
          Couldn’t load suggestions.
        </SuggestionsListNullcase>
      ) : loading || SHOW_LOADING_STATE_ON_SSR ? (
        <SuggestionsListNullcase className="_loading">
          <Spinner />
        </SuggestionsListNullcase>
      ) : !state.updates?.length ? (
        <SuggestionsListNullcase className="_empty">
          <Icon name="inbox" />
          <br />
          No {query.get('status') === 'closed' ? 'closed' : 'open'} suggestions.
        </SuggestionsListNullcase>
      ) : (
        <ul className={bem('-list')}>
          {state.updates?.map(u => (
            <li key={u._id} className={bem('-update')}>
              <Link to={`${url}/${u._id}`}>{u.name}</Link>
              <DateLine tag="small" time={u.createdAt} />
              <SuggestionStatus {...u} />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SuggestionsList;
