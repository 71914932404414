import PropTypes from 'prop-types';

export const LOG = {
  body: PropTypes.string,
  createdAt: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  slug: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  user: PropTypes.shape({ name: PropTypes.string }),
};
