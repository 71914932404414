import React from 'react';

import useClassy from '@core/hooks/useClassy';

import SmartLink from '@ui/SmartLink';

import styles from './style.module.scss';

interface ControlProps {
  data: {
    link_external?: boolean;
    link_url?: string;
    pageType?: 'RealtimePage';
    queryParam?: string;
    slug?: string;
    title?: string;
    type?: string;
  };
  direction: 'Next' | 'Previous';
  pageType: 'changelog' | 'docs' | 'reference';
}

function Control({ data, pageType, direction }: ControlProps) {
  const bem = useClassy(styles, 'PaginationControls');
  const isLinkType = data.type === 'link';
  const openLinkInNewTab = !!data.link_external && isLinkType;
  const separator = data.queryParam ? '?' : '/';
  const anchorProps = openLinkInNewTab ? { rel: 'noopener', target: '_blank' } : { target: '_self' };
  const props = isLinkType
    ? { href: data.link_url, ...anchorProps }
    : {
        to: `/${pageType}${separator}${data.pageType === 'RealtimePage' ? 'intro/' : ''}${
          data.slug || data.queryParam
        }`,
      };

  return (
    <SmartLink
      aria-label={`${direction} Page${data.title ? `: ${data.title}` : ''}`}
      className={bem('-link', direction === 'Next' ? '-link_right' : '-link_left')}
      {...props}
    >
      {direction === 'Previous' && <span className={`${bem('-icon')} icon-arrow-left2`} />}
      <div className={bem('-text', `-text_${direction === 'Previous' ? 'left' : 'right'}`)}>{data.title}</div>
      {direction === 'Next' && <span className={bem('-icon', 'icon-arrow-right2')} />}
    </SmartLink>
  );
}

export interface PaginationControlsProps {
  meta?: string;
  next?: ControlProps['data'];
  pageType: ControlProps['pageType'];
  prev?: ControlProps['data'];
}

export default function PaginationControls({ prev, next, meta, pageType }: PaginationControlsProps) {
  const bem = useClassy(styles, 'PaginationControls');

  return (
    <nav aria-label="Pagination Controls" className={bem('&', 'rm-Pagination')}>
      {!!prev && <Control data={prev} direction="Previous" pageType={pageType} />}
      {!!meta && <span className={bem('-meta')}>{meta}</span>}
      {!!next && <Control data={next} direction="Next" pageType={pageType} />}
    </nav>
  );
}
