import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { ConfigContext, ProjectContext } from '@core/context';
import useUserPermissions from '@core/hooks/useUserPermissions';

import Box from '@ui/Box';
import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import PaginationControls from '@ui/PaginationControls';

import DiscussPost from '../Post';
import classes from '../style.module.scss';

const DiscussSidebar = ({ numFAQs, tags }) => {
  const { search } = useLocation();
  return (
    <Flex align="stretch" className={classes['DiscussList-sidebar']} layout="col" tag="section">
      <Box className={classes['DiscussList-sidebar_box']} kind="card">
        <ul className={classes['DiscussList-sidebar_list']}>
          {numFAQs > 0 && (
            <li>
              <Link
                className={`Button Button_secondary_text Button_md ${classes['DiscussList-sidebar_link']} ${
                  search.match(/isFAQ=true/) ? 'active' : ''
                }`}
                to="/discuss?isFAQ=true"
              >
                <Icon name="star" />
                <span>FAQs</span>
              </Link>
            </li>
          )}
          <li>
            <Link
              className={`Button Button_secondary_text Button_md ${classes['DiscussList-sidebar_link']} ${
                search.match(/sorting=recent/) ? 'active' : ''
              }`}
              to="/discuss?sorting=recent"
            >
              <Icon name="trending-up" />
              <span>Recent</span>
            </Link>
          </li>
          {/**
            * HIDDEN UNTIL WE RESTORE DISCUSS.VIEW INCREMENT LOGIC
            * @see https://readmeio.slack.com/archives/C5B798RFZ/p1680639429426689
            <li>
              <Link
                className={`Button Button_secondary_text Button_md  ${classes['DiscussList-sidebar_link']} ${
                  search.match(/sorting=popular/) ? 'active' : ''
                }`}
                to="/discuss?sorting=popular"
              >
                <Icon name="award" />
                <span>Popular</span>
              </Link>
            </li>
            */}
          <li>
            <Link
              className={`Button Button_secondary_text Button_md ${classes['DiscussList-sidebar_link']} ${
                search.match(/sorting=open/) ? 'active' : ''
              }`}
              to="/discuss?sorting=open"
            >
              <Icon name="callout-info" />
              <span>Unanswered</span>
            </Link>
          </li>
        </ul>
      </Box>
      {tags?.length > 0 && (
        <Box className={classes['DiscussList-sidebar_box']} kind="card">
          {tags.map((tag, i) => {
            return (
              <Button
                key={`${tag}-${i}`}
                className={classes['DiscussList-sidebar_tag']}
                kind="secondary"
                outline
                size="sm"
                to={`/discuss?tag=${tag}`}
              >
                {tag}
              </Button>
            );
          })}
        </Box>
      )}
    </Flex>
  );
};

DiscussSidebar.propTypes = {
  numFAQs: PropTypes.number,
  tags: PropTypes.array,
};

const DiscussList = ({ discussions: posts, page, pages, numFAQs, tags }) => {
  const { domainFull } = useContext(ConfigContext);
  const { flags, subdomain } = useContext(ProjectContext).project;
  const { isLoggedIn } = useUserPermissions();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let perPage = parseInt(searchParams.get('perPage') || 10, 10);
  // calm yourself
  if (perPage > 100 && typeof window !== 'undefined') {
    const url = new URL(location.href);
    perPage = 100;
    url.searchParams.set('perPage', 100);
    window.history.pushState(null, null, url);
  }

  // Search params for when the user clicks to the previous page
  // Passing through the existing query params ensures that they don't get removed when adding new ones, ref: RM=4791
  const prevSearchParams = new URLSearchParams(searchParams);
  prevSearchParams.set('page', page - 1);
  prevSearchParams.set('perPage', perPage);
  const prevPage = page > 1 && { queryParam: prevSearchParams.toString() };

  // Search params for when the user clicks to the next page
  const nextSearchParams = new URLSearchParams(searchParams);
  nextSearchParams.set('page', page + 1);
  nextSearchParams.set('perPage', perPage);
  const nextPage = page < pages && { queryParam: nextSearchParams.toString() };

  // If we're on the first page, all posts are returned. Make sure we only display the first 10!
  // eslint-disable-next-line no-param-reassign
  if (page === 1) posts = posts?.slice(0, perPage);
  const hasTag = searchParams.get('tag');

  const LinkTag = flags?.disableAnonForum && !isLoggedIn ? 'a' : Link;

  return (
    <Flex align="stretch" className={classes.DiscussList} gap="md" layout="col">
      {posts && posts?.length < 1 ? (
        <div className={classes['Discuss-question']}>
          <div className={classes['Discuss-question_div']}>{`Nobody's asked a question ${
            hasTag ? 'with that tag ' : ''
          }yet. Be the first!`}</div>
          <LinkTag
            className={`Button Button_md ${classes['Discuss-question_button']}`}
            href={`${domainFull}/to/${subdomain}?redirect=/discuss-new`}
            to={'/discuss-new'}
          >
            Ask a Question
          </LinkTag>
        </div>
      ) : (
        <Flex className={classes['DiscussList-posts']} gap="lg">
          <Flex align="sretch" className={classes['DiscussList-posts-list']} gap="0" layout="col">
            {!!posts && posts.map((post, i) => <DiscussPost key={`${post.id}-${i}`} excerpt post={post} />)}
          </Flex>
          <DiscussSidebar numFAQs={numFAQs} tags={tags} />
        </Flex>
      )}
      {pages > 1 && (
        <PaginationControls meta={`${page} of ${pages}`} next={nextPage} pageType="discuss" prev={prevPage} />
      )}
    </Flex>
  );
};

DiscussList.propTypes = {
  discussions: PropTypes.array,
  numFAQs: PropTypes.number,
  page: PropTypes.number,
  pages: PropTypes.number,
  tags: PropTypes.array,
};

export default DiscussList;
